import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useCustomHook } from 'utils/useCustomHook';
import { Trader } from 'app/services/api/TraderApi/types';
import { initialListResponse } from 'app/services/api/CommonApiWithoutToken';
import { useGetTradersQuery } from 'app/services/api/TraderApi';
import './assets/css/style.css';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { MarchandType } from 'app/services/api/GeneralConfigApi/types';
import { CustomIcon } from 'app/components/CustomIcon';
import { Popover, Radio, RadioChangeEvent, Space } from 'antd';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
};

interface InputProps {
  isFullBorder: boolean;
}

const defaultCenter: google.maps.LatLngLiteral = {
  lat: 14.16442162001174,
  lng: -16.075998071806776,
};
const defaultZoom = 14;

const text = <span>Filtrer les contribuables</span>;

export const Maps = memo(() => {
  const { paginationRequest } = useCustomHook<Trader>();
  const [idTrader, setIdTrader] = useState<string>();
  const [showAutoCompletion, setShowAutoCompletion] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [center, setCenter] =
    useState<google.maps.LatLngLiteral>(defaultCenter);
  const [zoom, setZoom] = useState<number>(defaultZoom);
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const lat = Number(searchParams.get('lat'));
  const long = Number(searchParams.get('long'));

  const onChangeFilter = (e: RadioChangeEvent) => {
    if (e?.target?.value === 'payed') {
      setFilterTraders([]);
    } else {
      setFilterTraders(mapTraders?.data);
    }
  };

  const content = (
    <Radio.Group onChange={onChangeFilter} defaultValue="all">
      <Space direction="vertical">
        <Radio value="all">Tout</Radio>
        <Radio value="payed">Taxe payé</Radio>
        <Radio value="unpayed">Taxe impayé</Radio>
      </Space>
    </Radio.Group>
  );

  const { data: traders = initialListResponse } = useGetTradersQuery({
    ...paginationRequest,
    query: search,
  });

  const { data: mapTraders = initialListResponse, isSuccess } =
    useGetTradersQuery({
      ...paginationRequest,
      size: 100,
    });

  const [filterTraders, setFilterTraders] = useState<Trader[]>();

  useEffect(() => {
    if (isSuccess) {
      setFilterTraders(mapTraders?.data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (lat && long) {
      setCenter({ lat, lng: long });
      setZoom(19);
    } else {
      setCenter(defaultCenter);
      setZoom(defaultZoom);
    }
  }, [lat, long]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY!,
  });

  const FocusSearchInput = () => {
    setShowAutoCompletion(true);
  };

  const handleBlur = () => {
    setShowAutoCompletion(false);
  };

  const handleZoomChanged = useCallback(() => {
    if (zoom !== map?.getZoom()) {
      setZoom(map?.getZoom()!);
    }
  }, [map, zoom]);

  const handleCenterChanged = useCallback(() => {
    if (
      center?.lat !== map?.getCenter()?.lat()! &&
      center?.lng !== map?.getCenter()?.lng()!
    ) {
      setCenter({
        lat: map?.getCenter()?.lat()!,
        lng: map?.getCenter()?.lng()!,
      });
    }
  }, [center?.lat, center?.lng, map]);

  const handleClickSearchItem = useCallback(
    (trader: Trader) => () => {
      setCenter({ lat: trader?.latitude, lng: trader?.longitude });
      setZoom(20);
    },
    [],
  );

  return isLoaded ? (
    <>
      {showAutoCompletion && <Backdrop onClick={handleBlur} />}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        onCenterChanged={handleCenterChanged}
        options={{
          styles: [
            {
              elementType: 'labels',
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
        onLoad={localMap => {
          localMap.setCenter(defaultCenter);
          localMap?.setZoom(defaultZoom);
          setMap(localMap);
        }}
      >
        {!(lat && long) && (
          <WrapperSearch>
            <Wrapper>
              <Input
                placeholder="Rechercher un contribuable"
                type="text"
                onFocus={FocusSearchInput}
                onChange={e => setSearch(e?.target.value)}
                isFullBorder={showAutoCompletion && search.length > 0}
              />
              <Popover
                placement="bottom"
                title={text}
                content={content}
                trigger="click"
              >
                <FilterIcon>
                  <CustomIcon
                    name="TuneRounded"
                    color="#000"
                    fontSize="large"
                  />
                </FilterIcon>
              </Popover>
            </Wrapper>
            {showAutoCompletion && search.length > 0 && (
              <AutoCompletion>
                {traders?.data?.length === 0 && (
                  <ItemSearch>
                    <Span>Aucun resultat trouvé !</Span>
                  </ItemSearch>
                )}
                {traders?.data?.map((trader, key) => (
                  <ItemSearch onClick={handleClickSearchItem(trader)} key={key}>
                    <Image
                      src="https://maps.gstatic.com/consumer/images/icons/2x/place_grey650.png"
                      alt="map_image"
                    />
                    <Span>
                      {trader?.type === MarchandType.MORALE
                        ? trader?.firstName
                        : trader?.firstName + ' ' + trader?.lastName}
                    </Span>
                  </ItemSearch>
                ))}
              </AutoCompletion>
            )}
          </WrapperSearch>
        )}
        {lat && long ? (
          <Marker position={{ lat, lng: long }} />
        ) : (
          filterTraders?.map(trader => (
            <>
              <Marker
                position={{ lat: trader?.latitude, lng: trader?.longitude }}
                label={{
                  text: `${
                    trader?.type === MarchandType.MORALE
                      ? trader?.firstName
                      : trader?.firstName + ' ' + trader?.lastName
                  }`,
                  className: 'shape-title',
                  color: `${trader?.goodStanding ? `#225927` : `red`}`,
                }}
                icon={{
                  url: `${
                    trader?.goodStanding
                      ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                      : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                  }`,
                  scaledSize: new google.maps.Size(40, 40),
                }}
                onClick={() => setIdTrader(trader?.id)}
              />
              {idTrader && idTrader === trader?.id && (
                <InfoWindow
                  position={{ lat: trader?.latitude, lng: trader?.longitude }}
                  onCloseClick={() => setIdTrader(undefined)}
                >
                  <BlockInfo>
                    <Title>
                      {trader?.type === MarchandType.MORALE
                        ? trader?.firstName
                        : trader?.firstName + ' ' + trader?.lastName}
                    </Title>
                    <OtherInfo>{trader?.codeTrader}</OtherInfo>
                    <OtherInfo>{trader?.zoneName}</OtherInfo>
                    <OtherInfo>{trader?.retailActivity}</OtherInfo>
                    <PhoneNumberInfo
                      target="_blank"
                      href={`https://wa.me/${trader?.phoneNumberVm?.phoneNumber}`}
                    >
                      {trader?.phoneNumberVm?.phoneNumber}
                    </PhoneNumberInfo>
                    <Link onClick={handleClickSearchItem(trader)}>
                      Voir l'emplacement
                    </Link>
                  </BlockInfo>
                </InfoWindow>
              )}
            </>
          ))
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
});

const FilterIcon = styled.div`
  position: absolute;
  top: 55%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
`;

const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 0;
`;

const Title = styled.div`
  font-family: Nunito;
  font-size: 14px;
  color: #333;
  font-weight: bold;
`;

const OtherInfo = styled.div`
  font-family: Nunito;
  font-size: 13px;
  color: #333;
`;

const PhoneNumberInfo = styled.a`
  font-family: Nunito;
  font-size: 13px;
  color: #23d366;
  cursor: pointer;

  &:hover {
    color: #23d366;
  }
`;

const Link = styled.div`
  font-family: Nunito;
  font-size: 13px;
  color: #1a73e8;
  text-decoration: underline;
  cursor: pointer;
`;

const Input = styled.input<InputProps>`
  height: 55px;
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  border-radius: ${({ isFullBorder }) =>
    !isFullBorder ? '8px' : '8px 8px 0 0'};
  background-color: #ffffff;
  font-size: 15px;
  font-family: Nunito;
  color: #707070;
  border: none;

  &:focus-visible {
    outline: none;
  }
`;

const WrapperSearch = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  z-index: 99999999;
`;

const AutoCompletion = styled.div`
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  font-size: 15px;
  overflow: visible;
  width: 100%;
  padding: 8px 0;
  border-top: 1px solid rgba(112, 112, 112, 0.3);
  animation: fadeIn 1s;
  max-height: 200px;
  overflow-y: scroll;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ItemSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px 18px;
  cursor: pointer;

  &:hover {
    background: rgba(112, 112, 112, 0.11);
  }
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const Span = styled.span`
  color: #3c4043;
  font-size: 13px;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
`;
