import styled from 'styled-components';
import { ButtonProps } from '../..';
import BaseButtonStyle from '../BaseButtonStyle';

const OutlineButton = styled.button<ButtonProps>`
  ${BaseButtonStyle}
  background-color: white;
  color: #414141;
  border: 1px solid #414141;
`;

export default OutlineButton;
