/**
 *
 * Asynchronously loads the component for Invoices
 *
 */

import { lazyLoad } from 'utils/loadable';
import { PageLoadingIndicator } from 'app/components/PageLoadingIndicator';

export const TaxTypes = lazyLoad(
  () => import('./index'),
  module => module.TaxTypes,
  {
    fallback: <PageLoadingIndicator />,
  },
);
