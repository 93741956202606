/* --- STATE --- */

import { PaginationRequest } from '../CommonApiWithoutToken/types';

export interface GeneralConfig {
  code: string;
  id?: string;
  label: string;
  rang?: number;
  referenceControle?: string;
  typeParam?: TypeOfParameter;
  iconeName?: string;
}

export enum MarchandType {
  PHYSIQUE = 'Personne Physique',
  MORALE = 'Personne Morale',
}

export enum TypeOfParameter {
  SEXE = 'SEXE',
  TYPE_DE_PIECES = 'TYPE_DE_PIECES',
  CIVILITE = 'CIVILITE',
  TYPE_ACTEUR = 'TYPE_ACTEUR',
  FORME_JURIDIQUE = 'FORME_JURIDIQUE',
  LIEN_DE_PARENTE = 'LIEN_DE_PARENTE',
  COTATION_ACTEUR = 'COTATION_ACTEUR',
  CATEGORIE_ACTEUR = 'CATEGORIE_ACTEUR',
  ACTIVITE_ACTEUR = 'ACTIVITE_ACTEUR',
  PROFESSION = 'PROFESSION',
  NIVEAU_ETUDE = 'NIVEAU_ETUDE',
  SITUATION_MATRIMONIALE = 'SITUATION_MATRIMONIALE',
  NIVEAU_DE_REVENUE = 'NIVEAU_DE_REVENUE',
  LOCALITE = 'LOCALITE',
  RESPONSABILITE = 'RESPONSABILITE',
  VALEUR_TYPE = 'VALEUR_TYPE',
  PAIEMENT_TAXE_TYPE = 'PAIEMENT_TAXE_TYPE',
  PAIEMENT_MODE = 'PAIEMENT_MODE',
  TURNOVER = 'TURNOVER',
}

export interface DtoPaginationRequestTypeParm {
  typeParam: TypeOfParameter;
  paginationRequest: PaginationRequest;
}

export enum SEXE {
  MASCULIN = 'MASCULIN',
  FEMININ = 'FEMININ',
}
