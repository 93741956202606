/**
 *
 * Asynchronously loads the component for Layout
 *
 */

import { lazyLoad } from 'utils/loadable';
import { PageLoadingIndicator } from '../PageLoadingIndicator';

export const LayoutDashboard = lazyLoad(
  () => import('./index'),
  module => module.LayoutDashboard,
  {
    fallback: <PageLoadingIndicator />,
  },
);
