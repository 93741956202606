import { createGlobalStyle } from 'styled-components';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    padding: 0;
    margin: 0;
  }

  * {
    font-family: Nunito
  }
   .Toastify__toast-container {
     width: auto !important;
   }

   .Toastify__close-button {
      align-self: center !important;
    }

    .ant-table-tbody > tr > td {
      padding: 0 16px;
    }

   #success-toast-container .Toastify__toast-container .Toastify__toast {
      border-radius: 10px !important;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
      border: solid 1px #31d101 !important;
   }

   #error-toast-container .Toastify__toast-container .Toastify__toast {
      border-radius: 10px !important;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
      border: solid 1px red !important;
   }

   .modal{
      z-index: 1050 !important;
   }

   /* Hide scrollbar for Chrome, Safari and Opera */
  div::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  div {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }


`;
