import styled from 'styled-components';
import { ButtonProps } from '../..';
import BaseButtonStyle from '../BaseButtonStyle';

const PrimaryButton = styled.button<ButtonProps>`
  ${BaseButtonStyle}
  background-color: #225828;
`;

export default PrimaryButton;
