/**
 *
 * CustomButton
 *
 */
import React, { CSSProperties, memo } from 'react';
import { SyncLoader } from 'react-spinners';
import DangerButton from './styled-components/DangerButton';
import GreyButton from './styled-components/GreyButton';
import OutlineButton from './styled-components/OutlineButton';
import PrimaryButton from './styled-components/PrimaryButton';
import SecondaryButton from './styled-components/SecondaryButton';

interface Props extends ButtonProps {
  type?: 'primary' | 'secondary' | 'grey' | 'outline' | 'danger';
  text: string;
  icon?: any;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  style?: CSSProperties;
  value?: any;
}

export interface ButtonProps {
  fullWidth?: boolean;
  height?: string;
  fontSize?: string;
  loading?: boolean;
}

type ButtonType = 'submit' | 'reset' | 'button' | 'danger';

export const CustomButton = memo((props: Props) => {
  const { text, icon, type, loading = false, ...rest } = props;

  let Component = PrimaryButton;
  let buttonType: ButtonType = 'submit';

  switch (type) {
    case 'secondary':
      Component = SecondaryButton;
      buttonType = 'button';
      break;
    case 'grey':
      Component = GreyButton;
      buttonType = 'reset';
      break;
    case 'outline':
      Component = OutlineButton;
      buttonType = 'reset';
      break;
    case 'danger':
      Component = DangerButton;
      buttonType = 'button';
      break;
  }

  return (
    <Component type={buttonType} disabled={loading} {...rest}>
      {(!loading && icon) ?? ''} {!loading && text}
      {loading && <SyncLoader color={'white'} loading={loading} size={10} />}
    </Component>
  );
});
