/* --- STATE --- */

export interface InfoUser {
  activated: boolean;
  authorities: string[];
  createdBy: string;
  createdDate: string;
  email: string;
  firstName: string;
  id: string;
  imageUrl: string;
  langKey: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  lastName: string;
  login: string;
}

export interface Password {
  currentPassword: string;
  newPassword: string;
}

export enum TypeOfUser {
  AGENT = 'ROLE_AGENT',
  COMMERCANT = 'ROLE_COMMERCANT',
  SUPERVISOR = 'ROLE_SUPERVISOR',
  ADMIN = 'ROLE_ADMIN',
}
