/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import { rootApi, rootApiFlag, rootApiWithoutToken } from 'app/services/api';
import Auth from 'app/services/slice/Auth';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistReducer } from 'redux-persist';

const REACT_APP_SECRET_KEY_REDUX_PERSIST =
  '49CY9Bh7$Q9Q!g5sPZMUNq*dhK*2vdf7^jIVcTyiJ01DWDEVuT5E9cjk#FpQ!!';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  timeout: 1,
  writeFailHandler: error => console.log('ERROR PERSISTING DATA', error),
  transforms: [
    encryptTransform({
      secretKey: REACT_APP_SECRET_KEY_REDUX_PERSIST,
      onError: function (error) {
        // Handle the error.
        console.error(error);
      },
    }),
  ],
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return combineReducers({
      auth: persistReducer(persistConfig, Auth),
      [rootApi.reducerPath]: rootApi.reducer,
      [rootApiFlag.reducerPath]: rootApiFlag.reducer,
    });
  } else {
    return combineReducers({
      ...injectedReducers,
      auth: persistReducer(persistConfig, Auth),
      [rootApi.reducerPath]: rootApi.reducer,
      [rootApiFlag.reducerPath]: rootApiFlag.reducer,
    });
  }
}
