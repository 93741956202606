import { toast } from 'react-toastify';
import { Toast } from 'app/components/Toast/Loadable';
import { ReactNode } from 'react';

type ToastType = 'success' | 'error';

export const showToast = (type: ToastType, children: ReactNode | string) => {
  toast(
    <Toast>{type === 'error' ? '❌  ' + children : '✅  ' + children}</Toast>,
    {
      containerId:
        type === 'success'
          ? 'success-toast-container'
          : 'error-toast-container',
    },
  );
};
