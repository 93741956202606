/**
 *
 * CustomInputSearch
 *
 */
import React, { useState } from 'react';
import { Form, Input, InputProps } from 'antd';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import './assets/css/styles.css';

interface Props extends InputProps {
  name: string;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
}

export function CustomInputSearch(props: Props) {
  const { name, placeholder, readOnly = false, type = 'text', ...rest } = props;

  const [help, setHelp] = useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'email' && e.target.value.length > 5) {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      if (!regex.test(e.target.value)) {
        setHelp('Adresse email invalide.');
      } else {
        setHelp('');
      }
    }
  };
  return (
    <Input
      prefix={<SearchRoundedIcon />}
      readOnly={readOnly}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      className="input-text"
      {...rest}
    />
  );
}
