/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import FontFaceObserver from 'fontfaceobserver';

// Use consistent styling
import 'sanitize.css/sanitize.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';

import { App } from 'app';
import { ToastContainer } from 'react-toastify';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

import reportWebVitals from 'reportWebVitals';

import { persistStore } from 'redux-persist';

// Initialize languages
import './locales/i18n';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

// Observe loading of Nunito (to remove 'Nunito', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Nunito', {});

// When Nunito is loaded, add a font-family using Nunito to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

export const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <HelmetProvider>
          <React.StrictMode>
            <ToastContainer
              enableMultiContainer
              containerId={'success-toast-container'}
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop
            />
            <ToastContainer
              enableMultiContainer
              containerId={'error-toast-container'}
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop
            />
            <App />
          </React.StrictMode>
        </HelmetProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
