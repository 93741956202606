import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 35px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 895px) {
    font-size: 28px;
  }

  @media screen and (max-width: 404px) {
    font-size: 25px;
  }
`;
