/**
 *
 * CustomInputPassword
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import './assets/css/styles.css';
import { Input, InputProps } from 'antd';
import { InputPropsType } from '../CustomInputText';
import { Field, FieldProps } from 'formik';
import { Error } from '../Error';

interface Props extends InputProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  validate?: (value: any) => undefined | string | Promise<any>;
}

export const CustomInputPassword = memo((props: Props) => {
  const { name, label, placeholder, disabled, validate } = props;

  return (
    <FieldContainer>
      <Label htmlFor={name}>{label}</Label>{' '}
      <Field name={name} validate={validate}>
        {({
          field: { value },
          form: { setFieldValue },
          meta: { error },
        }: FieldProps) => (
          <>
            <InputPassword
              isError={error}
              placeholder={placeholder}
              disabled={disabled}
              onChange={e => setFieldValue(name, e.target?.value)}
              value={value}
            />
            {error && <Error>{error}</Error>}
          </>
        )}
      </Field>
    </FieldContainer>
  );
});

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #000;
  font-family: Nunito;
  margin-bottom: 3px;
`;

const InputPassword = styled(Input.Password)<InputPropsType>`
  height: 55px;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 15px;
  font-family: Nunito;
  color: #707070;
  border: solid 1px ${({ isError }) => (isError ? '#ff0001' : ' #707070')};

  &:focus-visible {
    outline: none !important;
  }
  &:hover {
    border: solid 1px ${({ isError }) => (isError ? '#ff0001' : ' #707070')} !important;
  }

  &:focus {
    border: solid 1px ${({ isError }) => (isError ? '#ff0001' : ' #707070')} !important;
  }
`;
