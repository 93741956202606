import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { AuthState } from './types';

export const initialState: AuthState = {
  isAuthenticated: false,
  access_token: undefined,
  expires_in: 0,
  id_token: undefined,
  notBeforePolicy: 0,
  refresh_expires_in: 0,
  refresh_token: undefined,
  scope: '',
  session_state: '',
  token_type: '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<AuthState>) {
      state.isAuthenticated = true;
      state.access_token = action.payload.access_token;
      state.id_token = action.payload.id_token;
      state.expires_in = action.payload.expires_in;
      state.notBeforePolicy = action.payload.notBeforePolicy;
      state.refresh_expires_in = action.payload.refresh_expires_in;
      state.refresh_token = action.payload.refresh_token;
      state.scope = action.payload.scope;
      state.session_state = action.payload.session_state;
      state.token_type = action.payload.token_type;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.access_token = undefined;
      state.expires_in = 0;
      state.id_token = undefined;
      state.notBeforePolicy = 0;
      state.refresh_expires_in = 0;
      state.refresh_token = undefined;
      state.scope = '';
      state.session_state = '';
      state.token_type = '';
    },
  },
});

export const { actions: authActions } = slice;
export default slice.reducer;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
