/**
 *
 * Asynchronously loads the component for Toast
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Toast = lazyLoad(
  () => import('./index'),
  module => module.Toast,
  {
    fallback: <div>...Loading</div>,
  },
);
