/**
 *
 * Asynchronously loads the component for Login
 *
 */

import { lazyLoad } from 'utils/loadable';
import { PageLoadingIndicator } from 'app/components/PageLoadingIndicator';

export const Login = lazyLoad(
  () => import('./index'),
  module => module.Login,
  {
    fallback: <PageLoadingIndicator />,
  },
);
