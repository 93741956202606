/**
 *
 * Asynchronously loads the component for ForgotPassword
 *
 */

import { lazyLoad } from 'utils/loadable';
import { PageLoadingIndicator } from 'app/components/PageLoadingIndicator';

export const ForgotPassword = lazyLoad(
  () => import('./index'),
  module => module.ForgotPassword,
  {
    fallback: <PageLoadingIndicator />,
  },
);
