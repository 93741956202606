import styled from 'styled-components';

interface Props {
  margin?: string;
}

export const ButtonContainer = styled.div<Props>`
  margin: ${({ margin }) => margin ?? '20px 0'};
  display: flex;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;
