/**
 *
 * UpdatePassword
 *
 */
import { CustomButton } from 'app/components/CustomButton';
import { CustomForm } from 'app/components/CustomForm';
import { CustomInputPassword } from 'app/components/CustomInputPassword';
import { PageHeader } from 'app/components/PageHeader';
import { ChangePasswordValidation } from 'app/pages/auth/FinishProcessForgotPassword/validation';
import { ButtonContainer } from 'app/pages/auth/Login/styled-components/ButtonContainer';
import { rootApi } from 'app/services/api';
import { useChangePasswordMutation } from 'app/services/api/ProfilApi';
import { Password } from 'app/services/api/ProfilApi/types';
import { useAuthSlice } from 'app/services/slice/Auth';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getErrorMessage } from 'utils/error';
import { showToast } from 'utils/toast';

interface Props {}

export const UpdatePassword = memo((props: Props) => {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const [updatePassword, { isLoading }] = useChangePasswordMutation();
  const navigate = useNavigate();
  const onFinish = useCallback(
    values => {
      const dto: Password = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      };
      updatePassword(dto)
        .unwrap()
        .then(() => {
          showToast('success', 'Votre mot de passe a été modifié avec succès');
          dispatch(actions.logout());
          dispatch(rootApi.util.resetApiState());
          navigate('/');
        })
        .catch(error => {
          showToast('error', getErrorMessage(error));
        });
    },
    [actions, dispatch, navigate, updatePassword],
  );
  return (
    <div>
      <PageHeader
        title="Changement de mot de passe"
        subtitle="Vous êtes sur le point de changer de votre mot de passe."
        withSearch={false}
      />
      <Container>
        <div style={{ width: '45%' }}>
          <CustomForm
            validationSchema={ChangePasswordValidation}
            initialValues={{
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            onSubmit={values => onFinish(values)}
          >
            <CustomInputPassword
              name="currentPassword"
              label="Ancien mot de passe"
              placeholder="Entrez votre ancien mot de passe"
            />
            <CustomInputPassword
              name="newPassword"
              label="Nouveau mot de passe"
              placeholder="Entrez votre nouveau mot de passe"
            />
            <CustomInputPassword
              name="confirmNewPassword"
              label="Confirmation nouveau mot de passe"
              placeholder="Entrez à nouveau votre nouveau mot de passe"
            />

            <ButtonContainer>
              <CustomButton text="Annuler" type="grey" fullWidth />
              <CustomButton text="Confirmer" loading={isLoading} fullWidth />
            </ButtonContainer>
          </CustomForm>
        </div>
      </Container>
    </div>
  );
});

const Container = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #707070;
  border-radius: 10px;
  padding: 60px 0;
`;
