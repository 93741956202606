import { css } from 'styled-components';
import { ButtonProps } from '../..';

const BaseButtonStyle = css`
  height: ${(props: ButtonProps) => props.height ?? '50px'};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-size: ${props => props.fontSize ?? '16px'};
  min-width: 140px;
  border: none;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
`;

export default BaseButtonStyle;
