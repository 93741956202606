import styled from 'styled-components';
import { ButtonProps } from '../..';
import BaseButtonStyle from '../BaseButtonStyle';

const SecondaryButton = styled.button<ButtonProps>`
  ${BaseButtonStyle}
  background-color: #e6a81e;
`;

export default SecondaryButton;
