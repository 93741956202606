/**
 *
 * PageLoadingIndicator
 *
 */
import React, { memo } from 'react';
import { DotLoader } from 'react-spinners';
import styled from 'styled-components';

interface Props {
  height?: string;
}

interface StyledProps {
  height?: string;
}

export const PageLoadingIndicator = memo(({ height }: Props) => {
  return (
    <Wrapper>
      <DotLoader loading size={50} color="#225828" />
    </Wrapper>
  );
});

const Wrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || '100vh'};
`;
