/**
 *
 * CustomIcon
 *
 */
import React, { memo } from 'react';
import * as MuiIcons from '@mui/icons-material';
import styled from 'styled-components';

interface Props {
  name: string;
  color?: string;
  fontSize?: string;
}

export const CustomIcon = memo((props: Props) => {
  const { name, fontSize = '25px', color = '#414141' } = props;
  const Icon = MuiIcons[name] ?? '';

  return (
    <WrapperIcon>{Icon && <Icon style={{ fontSize, color }} />}</WrapperIcon>
  );
});

const WrapperIcon = styled.span`
  margin-right: 5px;
`;
