import {
  configureStore,
  getDefaultMiddleware,
  isRejected,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  StoreEnhancer,
} from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { rootApi, rootApiFlag, rootApiWithoutToken } from 'app/services/api';
import { createInjectorsEnhancer } from 'redux-injectors';
import { showToast } from 'utils/toast';
import { getErrorMessage } from 'utils/error';
import { authActions } from 'app/services/slice/Auth';
import { persistor } from 'index';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isRejectedWithValue(action) && action?.payload?.status !== 401) {
      showToast('error', getErrorMessage(action?.payload));
    } else if (
      isRejected(action) &&
      action?.error?.name !== 'ConditionError' &&
      action?.payload?.status !== 401
    ) {
      showToast('error', getErrorMessage(action?.error));
    }

    if (isRejected(action) && action?.payload?.status === 401) {
      // window.location.href = '/';
      showToast('error', 'Votre session à expirée. Veuillez vous reconnecter.');
      api.dispatch(authActions.logout());
      persistor.purge();
      api.dispatch(rootApi.util.resetApiState());
      window.location.href = '/';
      // alert('Votre session à expirée. Veuillez vous reconnecter.');
    }

    return next(action);
  };

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      rtkQueryErrorLogger,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(rootApi.middleware)
        .concat(rootApiFlag.middleware),
    ],
    devTools: true,
    enhancers,
  });

  return store;
}
