/**
 *
 * Asynchronously loads the component for Users
 *
 */

import { lazyLoad } from 'utils/loadable';
import { PageLoadingIndicator } from 'app/components/PageLoadingIndicator';

export const Users = lazyLoad(
  () => import('./index'),
  module => module.Users,
  {
    fallback: <PageLoadingIndicator />,
  },
);
