import * as Yup from 'yup';

export const ChangePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string().required('Ce champs est obligatoire'),
  newPassword: Yup.string()
    .min(4, 'Le mot de passe doit contenir au moins 4 caracteres')
    .required('Ce champs est obligatoire'),
  confirmNewPassword: Yup.string()
    .required('Ce champs est obligatoire')
    .oneOf(
      [Yup.ref('newPassword')],
      'Vos mots de passe ne sont pas identiques',
    ),
});
