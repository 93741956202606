/**
 *
 * PageHeader
 *
 */
import React, { memo, useCallback } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import './assets/css/styles.css';
import { CustomButton } from '../CustomButton/Loadable';
import { CustomInputSearch } from '../CustomInputSearch';
import { useNavigate } from 'react-router-dom';
import { GoBack } from './styled-components/GoBack';
import { Title } from './styled-components/Title';
import { Help } from './styled-components/Help';
import Label from './styled-components/Label';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { CustomIcon } from '../CustomIcon';
import { ButtonContainer } from 'app/pages/auth/Login/styled-components/ButtonContainer';

interface Props {
  canGoBack?: boolean;
  title: string;
  subtitle: string;
  onSearch?: React.ChangeEventHandler<HTMLInputElement>;
  onClickAdd?: React.MouseEventHandler<HTMLButtonElement>;
  onClickScanQr?: () => void;
  withSearch?: boolean;
  placeholderSearch?: string;
  withAddButton?: boolean;
  withScanQrButton?: boolean;
  buttonText?: string;
  buttonScanQrText?: string;
  buttonIcon?: React.ReactNode;
  buttonType?: 'primary' | 'grey' | 'secondary' | 'outline';
}

export const PageHeader = memo((props: Props) => {
  const {
    canGoBack = false,
    title,
    subtitle,
    onSearch,
    onClickAdd,
    onClickScanQr,
    withAddButton = false,
    withScanQrButton = false,
    withSearch = true,
    buttonText,
    buttonScanQrText,
    placeholderSearch = 'Rechercher...',
    buttonType = 'secondary',
    buttonIcon = <AddRoundedIcon fontSize="small" />,
  } = props;

  const navigate = useNavigate();

  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {canGoBack && (
        <GoBack onClick={onGoBack}>
          <ArrowBackRoundedIcon fontSize="small" /> Retour
        </GoBack>
      )}
      <Row>
        <div>
          <Title>{title} </Title>
          <Help>
            <InfoRoundedIcon style={{ fontSize: 16 }} />
            <span>{subtitle}</span>
          </Help>
        </div>
        <ButtonContainer>
          {withScanQrButton && buttonScanQrText && (
            <CustomButton
              icon={
                <CustomIcon
                  name="QrCodeScannerRounded"
                  //color="#ffffff"
                  fontSize="small"
                />
              }
              text={buttonScanQrText}
              type="outline"
              onClick={onClickScanQr}
            />
          )}
          {withAddButton && buttonText && (
            <CustomButton
              icon={buttonIcon}
              text={buttonText}
              type={buttonType}
              onClick={onClickAdd}
            />
          )}
        </ButtonContainer>
      </Row>
      {withSearch && (
        <CustomInputSearch
          type="text"
          name="search"
          placeholder={placeholderSearch!}
          onChange={onSearch}
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 805px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
