import styled from 'styled-components';
import { ButtonProps } from '../..';
import BaseButtonStyle from '../BaseButtonStyle';

const GreyButton = styled.button<ButtonProps>`
  ${BaseButtonStyle}
  background-color: #c7c6c6;
  color: black;
`;

export default GreyButton;
