/**
 *
 * Asynchronously loads the component for Dashboard
 *
 */

import { lazyLoad } from 'utils/loadable';
import { PageLoadingIndicator } from 'app/components/PageLoadingIndicator';

export const Dashboard = lazyLoad(
  () => import('./index'),
  module => module.Dashboard,
  {
    fallback: <PageLoadingIndicator />,
  },
);
