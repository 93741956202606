import { PaginationRequest } from 'app/services/api/CommonApiWithoutToken/types';
import { useCallback, useState } from 'react';

export function useCustomHook<T>(id?: number): UseCustomHook<T> {
  const [showPopupAdd, setShowPopupAdd] = useState<boolean>(false);
  const [showPopupValidate, setShowPopupValidate] = useState<boolean>(false);
  const [showPopupMultiValidation, setShowPopupMultiValidation] =
    useState<boolean>(false);
  const [showPopupReject, setShowPopupReject] = useState<boolean>(false);
  const [showPopupAdjurn, setShowPopupAdjurn] = useState<boolean>(false);
  const [showPopupEdit, setShowPopupEdit] = useState<boolean>(false);
  const [showPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [showPopupDetails, setShowPopupDetails] = useState<boolean>(false);
  const [showPopupSuspend, setShowPopupSuspend] = useState<boolean>(false);
  const [showPopupWithdraw, setShowPopupWithdraw] = useState<boolean>(false);
  const [showPopupActivate, setShowPopupActivate] = useState<boolean>(false);
  const [showPopupDisable, setShowPopupDisable] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<T>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationRequest, setPaginationRequest] = useState<PaginationRequest>(
    {
      size: 10,
      page: 0,
      query: '',
      id,
    },
  );

  const togglePopupAdd = useCallback(() => {
    setShowPopupAdd(!showPopupAdd);
  }, [showPopupAdd]);

  const onShowPopupEdit = useCallback(
    (item: T) => () => {
      setShowPopupEdit(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupEdit = useCallback(() => {
    setShowPopupEdit(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupDelete = useCallback(
    (item: T) => () => {
      setShowPopupDelete(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupDelete = useCallback(() => {
    setShowPopupDelete(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupActivate = useCallback(
    (item: T) => () => {
      setShowPopupActivate(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupActivate = useCallback(() => {
    setShowPopupActivate(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupDisable = useCallback(
    (item: T) => () => {
      setShowPopupDisable(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupDisable = useCallback(() => {
    setShowPopupDisable(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupValidate = useCallback(
    (item: T) => () => {
      setShowPopupValidate(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupValidate = useCallback(() => {
    setShowPopupValidate(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupMultiValidation = useCallback(() => {
    setShowPopupMultiValidation(true);
  }, []);

  const onClosePopupMultiValidation = useCallback(() => {
    setShowPopupMultiValidation(false);
  }, []);

  const onShowPopupSuspend = useCallback(
    (item: T) => () => {
      setShowPopupSuspend(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupSuspend = useCallback(() => {
    setShowPopupSuspend(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupWithdraw = useCallback(
    (item: T) => () => {
      setShowPopupWithdraw(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupWithdraw = useCallback(() => {
    setShowPopupWithdraw(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupReject = useCallback(
    (item: T) => () => {
      setShowPopupReject(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupReject = useCallback(() => {
    setShowPopupReject(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupAdjurn = useCallback(
    (item: T) => () => {
      setShowPopupAdjurn(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupAdjurn = useCallback(() => {
    setShowPopupAdjurn(false);
    setSelectedItem(undefined);
  }, []);

  const onShowPopupDetails = useCallback(
    (item: T) => () => {
      setShowPopupDetails(true);
      setSelectedItem(item);
    },
    [],
  );

  const onClosePopupDetails = useCallback(() => {
    setShowPopupDetails(false);
    setSelectedItem(undefined);
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      setPaginationRequest({ ...paginationRequest, page: page - 1 });
      setCurrentPage(page);
    },
    [paginationRequest],
  );

  const onSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const query = typeof e === 'string' ? e : e.target.value;
      setPaginationRequest({
        ...paginationRequest,
        query,
        page: 0,
      });
    },
    [paginationRequest],
  );

  return {
    showPopupValidate,
    showPopupMultiValidation,
    showPopupReject,
    showPopupAdjurn,
    showPopupAdd,
    showPopupWithdraw,
    showPopupSuspend,
    showPopupActivate,
    showPopupDisable,
    togglePopupAdd,
    showPopupEdit,
    showPopupDetails,
    onShowPopupEdit,
    onClosePopupEdit,
    showPopupDelete,
    onShowPopupDelete,
    onClosePopupDelete,
    onShowPopupActivate,
    onClosePopupActivate,
    onShowPopupDisable,
    onClosePopupDisable,
    onShowPopupValidate,
    onClosePopupValidate,
    onShowPopupMultiValidation,
    onClosePopupMultiValidation,
    onShowPopupReject,
    onClosePopupReject,
    onShowPopupAdjurn,
    onClosePopupAdjurn,
    onShowPopupDetails,
    onClosePopupDetails,
    onShowPopupWithdraw,
    onClosePopupWithdraw,
    onShowPopupSuspend,
    onClosePopupSuspend,
    selectedItem,
    setSelectedItem,
    currentPage,
    onPageChange,
    paginationRequest,
    setPaginationRequest,
    onSearch,
  };
}

export interface UseCustomHook<T> {
  showPopupAdd: boolean;
  togglePopupAdd: () => void;
  showPopupEdit: boolean;
  onShowPopupEdit: (item: T) => () => void;
  onClosePopupEdit: () => void;
  showPopupDelete: boolean;
  onShowPopupDelete: (item: T) => () => void;
  onClosePopupDelete: () => void;
  showPopupValidate: boolean;
  showPopupMultiValidation: boolean;
  onShowPopupMultiValidation: () => void;
  onClosePopupMultiValidation: () => void;
  onShowPopupValidate: (item: T) => () => void;
  onClosePopupValidate: () => void;
  showPopupReject: boolean;
  onShowPopupReject: (item: T) => () => void;
  onClosePopupReject: () => void;
  showPopupAdjurn: boolean;
  onShowPopupAdjurn: (item: T) => () => void;
  onClosePopupAdjurn: () => void;
  showPopupActivate: boolean;
  onShowPopupActivate: (item: T) => () => void;
  onClosePopupActivate: () => void;
  showPopupDisable: boolean;
  onShowPopupDisable: (item: T) => () => void;
  onClosePopupDisable: () => void;
  showPopupDetails: boolean;
  onShowPopupDetails: (item: T) => () => void;
  onClosePopupDetails: () => void;
  showPopupWithdraw: boolean;
  onShowPopupWithdraw: (item: T) => () => void;
  onClosePopupWithdraw: () => void;
  showPopupSuspend: boolean;
  onShowPopupSuspend: (item: T) => () => void;
  onClosePopupSuspend: () => void;
  selectedItem?: T;
  setSelectedItem: (item?: T) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  paginationRequest: PaginationRequest;
  setPaginationRequest: (paginationRequest: PaginationRequest) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
}
