import styled from 'styled-components';

export const Help = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  color: #707070;

  @media screen and (max-width: 404px) {
    font-size: 12px;
  }
`;
